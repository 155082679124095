// src/context/StatusContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the Context
export const StatusContext = createContext();

// Create the Provider component
export const StatusProvider = ({ children }) => {
  const [status, setStatus] = useState(null); // Default status

  // You can add more functions to update the status or other values
  const updateStatus = (newStatus) => setStatus(newStatus);

  return (
    <StatusContext.Provider value={{ status, updateStatus }}>
      {children}
    </StatusContext.Provider>
  );
};

// Custom hook to use StatusContext more easily
export const useStatus = () => useContext(StatusContext);
