import React, { useState, useEffect } from 'react';
import { SyncOutlined } from '@ant-design/icons';  // For the spinner

const PendingBoundary = () => {
  // Assuming 'status' would come from context or other state (for demo, set manually to 'pending')
  const status = 'pending';

  const [currentText, setCurrentText] = useState("veuillez patienter");

  useEffect(() => {
    if (status === 'pending') {
      const textChangeInterval = setInterval(() => {
        setCurrentText((prevText) =>
          prevText === "veuillez patienter."
            ? "Nous sommes sur le point de commencer le traitement, cela pourrait prendre du temps, veuillez patienter."
            : "veuillez patienter."
        );
      }, 8000); // Change text every 2 seconds

      // Clear the interval when the component unmounts
      return () => clearInterval(textChangeInterval);
    }
  }, [status]);

  if (status === 'pending') {
    return (
      <>
        {/* Background blur */}
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark semi-transparent overlay
            backdropFilter: 'blur(5px)', // Background blur effect
            zIndex: 999, // Ensure it's above other content
          }}
        />
        
        {/* Popup Content */}
        <div
          style={{
            width:"50%",
            height:"200px",
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '30px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            zIndex: 1000,  // Ensure it's above the blurred background
          }}
        >
          <SyncOutlined spin style={{ fontSize: '50px', marginBottom: '20px' }} />
          <div
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
            //   animation: 'fadeInOut 10s infinite alternate',
            }}
          >
            {currentText}
          </div>
        </div>
      </>
    );
  }

  // If not pending, render children as usual
  return null;
};

export default PendingBoundary;
