import React, {Fragment, useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';

// import ResizeDetector from 'react-resize-detector';
// import {useResizeDetector} from 'react-resize-detector';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AppMain from '../../Layout/AppMain';
import AppSidebar from 'Layout/AppSidebar';
import {SideBar} from 'Layout/AppSidebar/sidebar';
import UserBox from 'Layout/AppHeader/Components/UserBox';
import {ReturnMenu, parseUrlSegments} from 'shared/extra';
import iconNotification from '../../shared/bell.png';
import SearchBox from 'Layout/AppHeader/Components/SearchBox';
import { AuthContext } from 'services';
import PendingBoundary from 'pendingStatus';
import { useStatus } from 'provStatus';

const Main = ({
  colorScheme,
  enableFixedHeader,
  enableFixedSidebar,
  enableFixedFooter,
  enableClosedSidebar,
  enableMobileMenu,
  enablePageTabsAlt,
  location,
}) => {

  const targetContext = useContext(AuthContext);
  const {loggedIn, user_data} = targetContext.state;

  const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);
  // const { width, height, ref } = useResizeDetector();
  const [appBarMessage, setappBarMessage] = useState('');
  const [collapsed, setCollapsed] = useState(true);
  const [statusFromUrl, seturl] = useState(null);

  const getAppBarMessage = title => {
    setappBarMessage(title);
  };

  const getcollapsed = value => {
    setCollapsed(value);
  };


 

  // data + i == parseUrlSegments(document.location.pathname).length+1
  //   ? ''
  //   : ' >',
  // )

  // // console.log(width);
   const { status, updateStatus } = useStatus();
  useEffect(() => {

    

  }, [location.pathname]);

 
  
  
  return location.pathname !== '/login' ? (
    // <ResizeDetector
    //   handleWidth
    //   render={({width}) => (
    // <div style={{
    //   overflow: "none",
    //   border:"1px solid red",
    //   height: "110vh"
    // }}>
    <div
      style={{
        display: 'flex',
        height: '100%',
        // overflow:"hidden",
        // width: '100%',
        // border: '1px solid black',

      
      }}>
       {status&& <PendingBoundary/>}
      {!status&&<SideBar
        getcollapsed={getcollapsed}
        getAppBarMessage={getAppBarMessage}
        // width={width}
      />}
      
     {!status&& <div
        style={
          collapsed
            ? {
                height: '50px',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '50px',
                flexDirection: 'row',
                background: 'orange',
                fontSize: '20px',
                fontWeight: '700',
                color: 'black',
                paddingLeft: '100px',
                border: '1px solid black',
                animationName: 'padding2',
              animationDuration: '.5s',
                // zIndex:999
              }
            : {
                height: '50px',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingRight: '50px',
                justifyContent: 'space-between',
                flexDirection: 'row',
                background: 'orange',
                fontSize: '20px',
                fontWeight: '700',
                color: 'black',
                paddingLeft: '270px',
                border: '1px solid black',
                animationName: 'padding',
              animationDuration: '.5s',
              // zIndex:999
                
              }
        }>
        <div>
          <ReturnMenu coll={user_data.type=="admin"?false:true} />

          
            <SearchBox/>
         
        </div>
        {/* <div>{appBarMessage}</div> */}
        <div style={{cursor: 'pointer'}} onClick={() => {}}>
          {/* {'Admin'} */}

          <UserBox />
        </div>
      </div>}
  {   <div
        style={{
          position: 'relative',
          overflow: 'scroll',
          overflowX: 'hidden',
          width: '100%',
          height: '95%',
          marginLeft: '10px',
          // paddingLeft:"50px",
          // marginTop: '70px',
          transform: 'translateY(55px)',
          // border:"1px solid red"
        }}>
        <AppMain />
        
      </div>}
    </div>
  ) : // )}
  // />
  null;
};

const mapStateToProps = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default
  withRouter(
    connect(
      mapStateToProps)
      (
        Main
      ));

// import React, {Fragment} from 'react';
// import {connect} from 'react-redux';
// import cx from 'classnames';
// import {withRouter} from 'react-router-dom';

// import ResizeDetector from 'react-resize-detector';

// import AppMain from '../../Layout/AppMain';
// import AppSidebar from 'Layout/AppSidebar';
// import {SideBar} from 'Layout/AppSidebar/sidebar';

// class Main extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       closedSmallerSidebar: false,
//     };
//   }

//   componentDidMount = () => {
//     // console.log(this.props.location.pathname);
//   };

//   render() {
//     let {
//       colorScheme,
//       enableFixedHeader,
//       enableFixedSidebar,
//       enableFixedFooter,
//       enableClosedSidebar,
//       closedSmallerSidebar,
//       enableMobileMenu,
//       enablePageTabsAlt,
//       location,
//     } = this.props;

//     return location.pathname !== '/login' ? (
//       <ResizeDetector
//         handleWidth
//         render={({width}) => (
//           <Fragment>
//             <div
//               style={{
//                 // border:"1px solid green",
//                 display: 'flex',
//                 height: '100%',
//                 width: '100%',
//               }}
//               // className={
//               //   cx(
//               //   'app-container app-theme-' + colorScheme,
//               //   {'fixed-header': enableFixedHeader},
//               //   {'fixed-sidebar': enableFixedSidebar || width < 1250},
//               //   {'fixed-footer': enableFixedFooter},
//               //   {'closed-sidebar': enableClosedSidebar || width < 1250},
//               //   {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
//               //   {'sidebar-mobile-open': enableMobileMenu},
//               //   )
//               // }
//             >
//               <div
//                 style={{
//                   // width:"100%",
//                   height: '50px',

//                   position: 'absolute',
//                   top: 0,
//                   right:0
// ,                  width: '98%',
//                   marginLeft: '0px',
//                   cursor: 'pointer',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-around',
//                   flexDirection: 'row',
//                   background: 'orange',
//                   border: '1px solid black',
//                 }}>{width}</div>
//               <SideBar  width={width}/>

//               <div
//                 style={{
//                   // border: "1px solid gray",
//                   overflow: 'scroll',
//                   overflowX: 'hidden',
//                   width: '100%',
//                   height: '100%',
//                   marginLeft: width>1200?'1px':"0px",
//                   marginTop: '70px',
//                 }}>
//                 <AppMain  />
//               </div>
//             </div>
//           </Fragment>
//         )}
//       />
//     ) : null;
//   }
// }

// const mapStateToProp = state => ({
//   colorScheme: state.ThemeOptions.colorScheme,
//   enableFixedHeader: state.ThemeOptions.enableFixedHeader,
//   enableMobileMenu: state.ThemeOptions.enableMobileMenu,
//   enableFixedFooter: state.ThemeOptions.enableFixedFooter,
//   enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
//   enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
//   enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
// });

// export default withRouter(connect(mapStateToProp)(Main));
